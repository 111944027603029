import React from 'react'
import Notification from '../components/Homemade/NotificationPage/NotificationPage'
import generatePropsOnBuildTime from '../helpers/generatePagePropsOnBuildTime'

const Alert = (props) => {

    return <Notification {...generatePropsOnBuildTime(props, {
        destination: 'loren ipsum'
    })} />
}

export default Alert