import React from "react"
import CircularProgress from "@material-ui/core/CircularProgress"
import "../../../../assets/css/shimmer.css"

const OngoingOrderLoading = () => {
  return (
    <div
      style={{
        display: 'flex', 
        justifyContent: 'center', 
        marginTop: '50%'
      }}
    >
      <CircularProgress/> 
    </div>
  )
}

export default OngoingOrderLoading
